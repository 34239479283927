export enum Locale {
	cs = "cs",
}

export type LocalizedString = Record<Locale, string>;

export type LocalizedStringArray = Record<Locale, string[]>;

export type LocalizedBoolean = Record<Locale, boolean>;

export type LocalizedID = Record<Locale, string>;

export const localeShortHandMap = {
	[Locale.cs]: "cz",
} satisfies LocalizedString;

export const localeNameMap: Record<Locale, string> = {
	[Locale.cs]: "Čeština",
};
