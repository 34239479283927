<script lang="ts">
	import type { MenuItem } from "../../../../core/schema/MenuItem";
	import type { Writable } from "svelte/store";
	import { pageSlugContextKey } from "../../contexts/pageSlugContextKey.js";
	import { createEventDispatcher, getContext } from "svelte";
	import { isMenuActive } from "../../utils/isMenuActive";
	import Link from "../Link.svelte";
	import type { LocalizedString } from "../../../../core/schema/Locale";
	import { getGlobalSettings } from "../../getGlobalSettings";
	import { nbspify } from "../../../../core/utils/nbspify.js";

	const { TENANT_LOCALE } = getGlobalSettings();
	const pageSlug = getContext<LocalizedString>(pageSlugContextKey);

	const dispatch = createEventDispatcher<{
		click: MenuItem;
	}>();

	export let menuItem: MenuItem;
	export const parentSubmenuItem: MenuItem["id"] | null = null;

	export let activeSubmenu: Writable<MenuItem["id"] | null>;

	function isSubmenuActive(submenu: MenuItem["id"] | null, menuItem: MenuItem): boolean {
		if (menuItem.id === submenu) {
			return true;
		}

		return menuItem.submenus.some((submenuItem) => isSubmenuActive(submenu, submenuItem));
	}

	const sebmenuItemLiClasses = "group/submenu cursor-pointer lg:px-3 xl:px-5 w-full";
	const submenuItemLinkClasses =
		"group-hover/submenu:text-primary transition-colors duration-300 text-secondary text-2xs tracking-[0.125rem] lg:text-2xs xl:text-xs py-3 px-5 w-full lg:px-0 text-center !block";
</script>

<!-- TODO - fix submenu on LG on menuItem hover, it is set to absolute position so it should not take up any space, but it does and makes the menu scroll -->
{#if menuItem.submenus.length > 0}
	<div
		class="z-50 grid grid-rows-[0fr] transition-[grid-template-rows,opacity] duration-300 ease-in-out lg:pointer-events-none lg:absolute lg:left-1/2 lg:right-0 lg:top-full lg:block lg:w-[20rem] lg:-translate-x-1/2 lg:rounded-lg lg:bg-white lg:opacity-0 lg:shadow-md lg:group-hover/menu:pointer-events-auto lg:group-hover/menu:opacity-100 {isSubmenuActive(
			$activeSubmenu,
			menuItem,
		) && 'grid-rows-[1fr]'}"
	>
		<ul class="flex flex-col items-center overflow-hidden">
			{#each menuItem.submenus as submenuItem}
				{@const isActive = isMenuActive(submenuItem, pageSlug[TENANT_LOCALE])}
				<li class={sebmenuItemLiClasses}>
					<div class="relative flex w-full items-center gap-2">
						<Link
							noUnderline
							class="{submenuItemLinkClasses} {isActive ? 'text-primary' : ''}"
							href={`/${submenuItem.path}`}
							target={submenuItem.target}
							on:click={() => {
								dispatch("click", submenuItem);
							}}
						>
							{nbspify(submenuItem.name)}
						</Link>
					</div>
				</li>
			{/each}
		</ul>
	</div>
{/if}
