<script lang="ts">
	import type { LinkProps } from "./LinkProps.js";
	import { generateLinkClasses } from "./LinkClasses.js";
	import Icon from "./Icon.svelte";

	type $$Props = LinkProps;

	$: ({
		asButton = false,
		noUnderline = false,
		icon,
		iconFront = false,
		iconClass,
		linkClass,
		disabled = false,
		variant,
		linkVariant,
		...restProps
	} = $$restProps as $$Props);

	$: linkClasses = [restProps.class, generateLinkClasses({ variant, linkClass, linkVariant, asButton, noUnderline })]
		.filter(Boolean)
		.join(" ");
</script>

<a
	{...restProps}
	class={linkClasses}
	href={restProps.href}
	on:click
	aria-disabled={!restProps.href || disabled}
	role={restProps.href ? "link" : "none"}
	rel={restProps.target === "_blank" ? "noopener noreferrer" : restProps.rel}
>
	{#if icon && iconFront}
		<Icon {icon} class="!aspect-[initial] {disabled ? 'text-secondary-300' : ''} {iconClass}" />
	{/if}
	<slot />
	{#if icon && !iconFront}
		<Icon {icon} class="!aspect-[initial] {disabled ? 'text-secondary-300' : ''} {iconClass}" />
	{/if}
</a>
