<script lang="ts">
	import type { ButtonProps } from "./ButtonProps.js";
	import { generateButtonClasses } from "./ButtonClasses.js";
	import Icon from "./Icon.svelte";

	type $$Props = ButtonProps;

	$: ({
		active = false,
		icon,
		iconFront = false,
		iconClass,
		disabled = false,
		variant = "primary",
		...restProps
	} = $$restProps as $$Props);

	$: type = restProps.type ?? "button";
	$: btnClasses = [restProps.class, generateButtonClasses({ variant, disabled, active })].filter(Boolean).join(" ");
</script>

<button {...restProps} class={btnClasses} {type} {disabled} on:click on:mousedown>
	{#if icon && iconFront}
		<Icon {icon} class="!aspect-[initial] {disabled ? 'text-secondary-300' : ''} {iconClass}" />
	{/if}
	<slot />
	{#if icon && !iconFront}
		<Icon
			{icon}
			class="!aspect-[initial] {disabled
				? 'group-hover:text-secondary-300 transition-colors duration-300'
				: ''} {iconClass}"
		/>
	{/if}
</button>
