<script lang="ts">
	import youtube from "../../../core/assets/icons/youtube.svg?raw";
	import facebook from "../../../core/assets/icons/fb.svg?raw";
	import instagram from "../../../core/assets/icons/ig.svg?raw";
	import rack from "../../../core/assets/rack.svg?raw";
	import linkedin from "../../../core/assets/icons/linkedin.svg?raw";
	import logistickeSystemy from "../assets/images/logisticke-systemy.png?webp&h=40&imagetools";
	import snek from "../../../core/assets/icons/fastest-snek.svg?raw";
	import bito from "../../../core/assets/icons/bito.svg?raw";
	import Icon from "./Icon.svelte";
	import CallUs from "./CallUs.svelte";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import { serviceEquipPathMap } from "../../../core/schema/paths/serviceEquipPathMap.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { serviceLogisticsPathMap } from "../../../core/schema/paths/serviceLogisticsPathMap.js";
	import { serviceInspectionPathMap } from "../../../core/schema/paths/serviceInspectionPathMap.js";
	import { podcastPathMap } from "../../../core/schema/paths/podcastPathMap.js";
	import { blogPathMap } from "../../../core/schema/paths/blogPathMap.js";
	import { contactPathMap } from "../../../core/schema/paths/contactPathMap.js";
	import { getFilteredReferencePath } from "../../../core/schema/paths/getFilteredReferencePath.js";
	import { CaseStudyCategory } from "../../../core/schema/CaseStudyCategory.js";
	import { helpPagePathMap } from "../../../core/schema/paths/helpPagePathMap.js";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import { serviceSparePartsPathMap } from "../../../core/schema/paths/serviceSparePartsPathMap.js";

	const { TENANT_LOCALE } = getGlobalSettings();

	const socials: Record<string, string>[] = [
		{
			urlSlug: "https://www.facebook.com/BITO.CZ",
			icon: facebook,
			name: "Facebook",
		},
		{
			urlSlug: "https://www.instagram.com/bito_cz/",
			icon: instagram,
			name: "Instagram",
		},
		{
			urlSlug: "https://www.youtube.com/channel/UCEMjm4mb67grxW6RT4P0ccw",
			icon: youtube,
			name: "YouTube",
		},
		{
			urlSlug: "https://www.linkedin.com/company/bito-cz/",
			icon: linkedin,
			name: "LinkedIn",
		},
	];

	const referenceLinks: Record<string, string>[] = [
		{
			urlSlug: `/${getFilteredReferencePath(CaseStudyCategory.ShelfSystem, TENANT_LOCALE)}`,
			title: "Regálové systémy",
		},
		{
			urlSlug: `/${getFilteredReferencePath(CaseStudyCategory.DurableContainers, TENANT_LOCALE)}`,
			title: "Odolné přepravky",
		},
		{
			urlSlug: `/${getFilteredReferencePath(CaseStudyCategory.SteelPlatforms, TENANT_LOCALE)}`,
			title: "Ocelové plošiny",
		},
		{
			urlSlug: `/${getFilteredReferencePath(CaseStudyCategory.StorageTowers, TENANT_LOCALE)}`,
			title: "Skladovací věže",
		},
		{
			urlSlug: `/${getFilteredReferencePath(CaseStudyCategory.Agv, TENANT_LOCALE)}`,
			title: "AGV - Automaticky řízený dopravní systém",
		},
	];
	const serviceLinks: Record<string, string>[] = [
		{
			urlSlug: `/${serviceEquipPathMap[TENANT_LOCALE]}`,
			title: "Vybavíme váš sklad podle potřeby",
		},
		{
			urlSlug: `/${serviceLogisticsPathMap[TENANT_LOCALE]}`,
			title: "Navrhneme logistické řešení pro každý sklad",
		},
		{
			urlSlug: `/${serviceInspectionPathMap[TENANT_LOCALE]}`,
			title: "Provedeme inspekci vašeho skladu",
		},
		{
			urlSlug: `/${serviceSparePartsPathMap[TENANT_LOCALE]}`,
			title: "Náhradní díly nebo servis? Není problém",
		},
	];
	const menuLinks: Record<string, string>[] = [
		{
			urlSlug: `/${helpPagePathMap[TENANT_LOCALE]}`,
			title: "Jak vám můžeme pomoci?",
		},
		{
			urlSlug: `/${podcastPathMap[TENANT_LOCALE]}`,
			title: "Podcast Kruté Sklady",
		},
		{
			urlSlug: `/${blogPathMap[TENANT_LOCALE]}`,
			title: "Blog",
		},
		{
			urlSlug: "https://www.logistikavpraxi.cz/",
			target: "_blank",
			title: "Konference Logistika v praxi",
		},
		{
			urlSlug: `/${contactPathMap[TENANT_LOCALE]}`,
			title: "Kontakt",
		},
	];
</script>

<CallUs />
<footer class="text-xs">
	<div class="bg-bito-black flex flex-col px-4 pb-24 pt-6 sm:p-8 lg:px-10 lg:pb-36">
		<div class="flex flex-col-reverse justify-between gap-8 lg:flex-row">
			<Icon icon={rack} class="h-16 w-full object-cover lg:max-w-[65%] xl:w-[63.4rem]" />
			<div class="flex items-center gap-4">
				<Icon icon={bito} class="h-16 w-32" />
				<StaticPicture image={logistickeSystemy} alt="logistické systémy" width={138} height={40} />
			</div>
		</div>

		<div class="mt-16 flex flex-wrap justify-between gap-5 lg:mt-24 xl:gap-0">
			<div class="flex grow flex-col items-start gap-4">
				<p class="text-primary text-[1.5rem] font-extrabold">Reference</p>
				<ul class="flex flex-col gap-3 text-xs text-gray-400">
					{#each referenceLinks as link}
						<li class="cursor-pointer hover:text-gray-500"><a href={link.urlSlug}>{nbspify(link.title)}</a></li>
					{/each}
				</ul>
			</div>

			<div class="flex grow flex-col items-start gap-4">
				<p class="text-primary text-[1.5rem] font-extrabold">{nbspify("Naše služby")}</p>
				<ul class="flex flex-col gap-3 text-xs text-gray-400">
					{#each serviceLinks as link}
						<li class="cursor-pointer hover:text-gray-500"><a href={link.urlSlug}>{nbspify(link.title)}</a></li>
					{/each}
				</ul>
			</div>

			<div class="flex grow flex-col items-start gap-4">
				<p class="text-primary text-[1.5rem] font-extrabold">Menu</p>
				<ul class="flex flex-col gap-3 text-xs text-gray-400">
					{#each menuLinks as link}
						<li class="cursor-pointer hover:text-gray-500">
							<a href={link.urlSlug} target={link.target ?? "_self"}>{nbspify(link.title)}</a>
						</li>
					{/each}
				</ul>
			</div>

			<div class="flex grow-[2] justify-start lg:justify-center">
				<div class="flex flex-col items-start gap-4">
					<p class="text-primary text-[1.5rem] font-extrabold">{nbspify("Sledujte nás")}</p>
					<div class="flex gap-6 text-white">
						{#each socials as social}
							<a aria-label={social.name} href={social.urlSlug}>
								<Icon icon={String(social.icon)} class="h-8 w-8 cursor-pointer hover:text-gray-300" />
							</a>
						{/each}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		class="flex w-full flex-col items-center justify-between gap-y-5 border-t border-gray-400 bg-[#333333] px-10 py-4 pb-24 text-center md:flex-row"
	>
		<p class="text-gray-400">
			<span class="font-bold">{nbspify("@ 2024 BITO Skladovací technika, s.r.o.")}</span> – www.dobralogistika.cz
		</p>
		<a href="https://www.fastest.cz/" class="flex items-center gap-3 text-gray-400 hover:text-gray-500">
			<p>Naskladnili <span class="font-bold">Fastest Solution, s.r.o.</span></p>
			<Icon icon={snek} class="h-7 w-14" />
		</a>
	</div>
</footer>
