import { IndexTag } from "../IndexTag.js";
import { Locale, type LocalizedString } from "../Locale.js";

export const indexTagNameMap = {
	[IndexTag.Podcast]: { [Locale.cs]: "Podcast" },
	[IndexTag.BlogPost]: { [Locale.cs]: "Článek" },
	[IndexTag.CaseStudy]: { [Locale.cs]: "Reference" },
	[IndexTag.Product]: { [Locale.cs]: "Produkt" },
	[IndexTag.Category]: { [Locale.cs]: "Kategorie" },
} satisfies Record<IndexTag, LocalizedString>;
