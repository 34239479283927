import type { ButtonProps, ButtonVariant } from "./ButtonProps.js";

export function generateButtonClasses({ variant, disabled }: ButtonProps): string {
	const btnVariants: Record<ButtonVariant, string> = {
		primary: "bg-secondary hover:bg-tertiary text-white group-hover:bg-tertiary",
		secondary:
			"border-gray bg-white text-bito-black hover:border-tertiary hover:text-tertiary group-hover:border-tertiary group-hover:text-tertiary border",
		transparent: "group text-black flex gap-2 items-center",
	};
	const variantClass = btnVariants[variant ?? "primary"];

	const disabledClass = disabled && "opacity-50 pointer-events-none";

	if (variant !== "transparent") {
		return [
			"text-2xs sm:text-xs flex justify-center items-center font-extrabold py-3 transition-all duration-200",
			variantClass,
			disabledClass,
		]
			.filter(Boolean)
			.join(" ");
	}

	return [variantClass, disabledClass].filter(Boolean).join(" ");
}
