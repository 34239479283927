<script lang="ts">
	import "../assets/css/algolia-results.css";
	import type { LiteClient } from "algoliasearch/lite";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { createPromiseStore } from "../../../core/utils/createPromiseStore.js";
	import type { Indexable } from "../../../core/schema/Indexable.js";
	import type { SearchMethodParams, SearchResponse } from "algoliasearch";
	import play from "../../../core/assets/icons/play.svg?raw";
	import Icon from "./Icon.svelte";
	import { indexTagNameMap } from "../../../core/schema/names/indexTagNameMap.js";
	import { IndexTag } from "../../../core/schema/IndexTag.js";
	import podcast from "../assets/images/podcast.png?webp&h=120&imagetools";
	import logo from "../../../core/assets/icons/bito.svg";
	import { nbspify } from "../../../core/utils/nbspify.js";

	export let searchText = "";

	const { ALGOLIA_APP_ID, ALGOLIA_API_KEY, ENV, PROJECT, TENANT_LOCALE } = getGlobalSettings();

	let client: LiteClient | undefined;
	let canMakeRequest = true;
	let nextSearch = "";
	$: search = createPromiseStore(async (params: SearchMethodParams) => {
		if (!client) {
			const { liteClient } = await import("algoliasearch/lite");
			// eslint-disable-next-line require-atomic-updates
			client = liteClient(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
		}
		return client.searchForHits<Indexable>(params);
	});

	let results: SearchResponse<Indexable>[] = [];

	async function onSearchTextChange(text: string): Promise<void> {
		nextSearch = text;
		if (!canMakeRequest) {
			return;
		}

		if (text.length <= 2) {
			results = [];
			return;
		}

		try {
			canMakeRequest = false;
			({ results } = await search.call({
				requests: [
					{
						indexName: `${ENV}_${PROJECT}_${TENANT_LOCALE}`,
						query: text,
					},
				],
			}));
		} finally {
			window.setTimeout(() => {
				canMakeRequest = true;
				if (nextSearch !== text) {
					void onSearchTextChange(nextSearch);
				}
			}, 250);
		}
	}
	$: void onSearchTextChange(searchText);

	function getImage(hit: Indexable): string | undefined {
		if (hit.image) {
			return hit.image;
		}

		if (hit._tags.includes(IndexTag.Podcast)) {
			return podcast;
		}

		return undefined;
	}

	function getHighlightedText(hit: Indexable, key: keyof Indexable): string {
		const { _highlightResult } = hit;
		if (!_highlightResult) {
			return "";
		}
		const property = _highlightResult[key];
		if (!property || !("value" in property) || typeof property.value !== "string") {
			return "";
		}

		return property.value;
	}

	/* eslint-disable svelte/no-at-html-tags */
</script>

<div class="grid grid-cols-[7.5rem,1fr,2.5rem]">
	{#each results.flatMap(({ hits }) => hits) as hit}
		{@const image = getImage(hit)}
		<a
			href={hit.url}
			target={hit.url.startsWith("/") ? "_self" : "_blank"}
			class="group/result relative col-span-full grid grid-cols-subgrid bg-white"
		>
			<img
				src={image ?? logo}
				alt={hit.title}
				class="h-24 w-full border-r border-gray-100"
				class:object-cover={image}
				class:p-8={!image}
			/>
			<div class="flex flex-col overflow-auto">
				<div class="flex flex-1 flex-col px-4 py-2.5">
					<span class="highlighted font-extrabold leading-6">
						{@html getHighlightedText(hit, "title")}
					</span>
					<span class="highlighted overflow-hidden text-ellipsis whitespace-nowrap text-xs leading-[1.3125rem]">
						{@html getHighlightedText(hit, "description")}
					</span>
				</div>
				<div class="flex">
					<span class="bg-primary group-hover/result:bg-tertiary text-2xs px-3.5 py-0.5 font-bold text-white">
						{#each hit._tags as tag}
							{nbspify(indexTagNameMap[tag][TENANT_LOCALE])}
						{/each}
					</span>
				</div>
			</div>
			<div class="flex flex-col justify-end">
				<Icon
					icon={play}
					class="group-hover/result:border-tertiary group-hover/result:text-tertiary text-secondary aspect-square w-full border-l border-t border-gray-100 p-3"
				/>
			</div>
			<div
				class="group-hover/result:border-tertiary absolute inset-0 -top-px border border-gray-100 first:border-t-0 group-hover/result:z-10"
			></div>
		</a>
	{:else}
		<span class="relative -top-px col-span-full p-6 border border-gray-100 bg-white">
			{#if searchText}
				{nbspify("Nic takového jsme nenašli…")}
			{:else}
				{nbspify("Začněte vyhledávat…")}
			{/if}
		</span>
	{/each}
</div>
