<script lang="ts">
	import type { MenuItem } from "../../../core/schema/MenuItem.js";
	import Drawer from "./Drawer.svelte";
	import MobileMenu from "./Menu/MobileMenu.svelte";
	import { showHeaderStore } from "../stores/showHeaderStore.js";
	import HeaderMenuItem from "./HeaderMenuItem.svelte";
	import search from "../../../core/assets/icons/search.svg?raw";
	import cross from "../../../core/assets/icons/cross.svg?raw";
	import bito from "../../../core/assets/icons/bito.svg?raw";
	import Icon from "./Icon.svelte";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import Burger from "./Burger.svelte";
	import AlgoliaResults from "./AlgoliaResults.svelte";
	import { scrollLockStore } from "../stores/scrollLockStore.js";
	import { onMount, tick } from "svelte";
	import { fade } from "svelte/transition";
	import Link from "./Link.svelte";
	import { contactPathMap } from "../../../core/schema/paths/contactPathMap.js";

	export let menu: MenuItem[];
	export let bottomBorderFixed: boolean;

	const { TENANT_LOCALE } = getGlobalSettings();

	let isMenuOpen = false;

	function toggleMenu(): void {
		isMenuOpen = !isMenuOpen;
	}

	let header: HTMLElement | null;

	let scrollPosition = 0;
	let scrollFromLastDirectionChange = 0;
	const scrollTolerance = 25;
	let showHeader = true;

	function onScroll(event: UIEvent & { currentTarget: EventTarget & Window }): void {
		const { scrollY } = event.currentTarget;
		const newScrollPosition = Math.max(scrollY, 0);
		const oldDirection = scrollFromLastDirectionChange < 0 ? "up" : "down";
		const newDirection = newScrollPosition > scrollPosition ? "down" : "up";

		if (oldDirection !== newDirection) {
			scrollFromLastDirectionChange = 0;
		}
		scrollFromLastDirectionChange += newScrollPosition - scrollPosition;

		scrollPosition = newScrollPosition;

		if (Math.abs(scrollFromLastDirectionChange) > scrollTolerance) {
			showHeader = scrollFromLastDirectionChange < 0;
		}
	}

	let searchText = "";
	let isSearching = false;
	let desktopSearchInput: HTMLInputElement | null = null;
	let mobileSearchInput: HTMLInputElement | null = null;
	$: {
		if (!isSearching) {
			scrollLockStore.set(false);
		}
	}
	$: {
		if ($scrollLockStore !== isSearching && isSearching) {
			scrollLockStore.set(isSearching);
		}
	}

	// When user clicks before JavaScript loads/webpage hydrates.
	onMount(() => {
		if (document.activeElement instanceof HTMLInputElement) {
			isSearching = [desktopSearchInput, mobileSearchInput].includes(document.activeElement);
		}
	});
</script>

<svelte:window on:scroll={onScroll} />

{#if menu.length > 0}
	<Drawer bind:isOpen={isMenuOpen} position="left" dontShowCloseButton>
		<MobileMenu bind:isOpen={isMenuOpen} {menu} />
	</Drawer>
{/if}

{#if isSearching}
	<button
		transition:fade={{ duration: 300 }}
		type="button"
		tabindex="-1"
		class="bg-primary/15 fixed inset-0 z-30 cursor-default backdrop-blur-sm"
		on:click={() => {
			isSearching = false;
		}}
	></button>
{/if}

<!-- TODO - grayscale added to prevent flickering of header on page scroll in FireFox - https://stackoverflow.com/questions/30411909/css-transition-flickering-in-firefox -->
<header
	bind:this={header}
	class="fixed left-0 right-0 top-0 z-40 h-[5rem] w-full border-b border-solid bg-white/85 grayscale-[1%] transition-[border,transform] duration-300 lg:h-[5.125rem]"
	class:-translate-y-full={!showHeader && !$showHeaderStore}
	class:translate-y-0={showHeader || $showHeaderStore}
	class:border-gray={showHeader}
	class:border-transparent={scrollPosition === 0 && !bottomBorderFixed}
>
	<div
		class="flex h-full w-full grid-cols-[auto,1fr] items-center justify-between xl:grid 2xl:grid-cols-[1fr,auto,1fr]"
	>
		<div
			class="text-bito-black !mr-0 flex h-full items-center justify-between !pr-0 transition-all duration-300 xl:container xl:h-[5.063rem] xl:justify-start 2xl:col-start-2"
		>
			<a href="/" class="ml-2 flex flex-1 items-center gap-2 px-4 sm:px-6 xl:flex-grow-0 xl:px-12">
				<Icon icon={bito} class="h-8 w-16" />
				<p class="text-primary text-4xs font-bold uppercase">Logistické<br />systémy</p>
			</a>

			<div class="hidden xl:contents">
				{#each menu as menuItem}
					<HeaderMenuItem title={menuItem.name} urlSlug={menuItem.path} target={menuItem.target} />
				{/each}

				<Link
					href="/{contactPathMap[TENANT_LOCALE]}"
					asButton
					variant="primary"
					class="flex h-full items-center justify-center px-8">Kontakty</Link
				>
			</div>
		</div>
		<div class="flex h-full">
			<button
				class="text-2xs border-gray flex h-full cursor-pointer items-center gap-4 border-x px-5 font-extrabold leading-tight xl:hidden"
				on:click={toggleMenu}
			>
				<Burger />
				Menu
			</button>
			<div class="h-full xl:relative xl:flex-1">
				<input
					bind:this={desktopSearchInput}
					type="search"
					class="text-primary placeholder:text-bito-black absolute inset-0 hidden items-center bg-transparent p-6 pl-[3.25rem] font-extrabold leading-none outline-none placeholder:font-normal xl:block"
					bind:value={searchText}
					placeholder="Vyhledat"
					on:focus={() => {
						isSearching = true;
					}}
				/>
				<button
					aria-label="Vyhledat"
					type="button"
					class="bottom-0 left-0 top-0 flex h-full items-center p-6 text-gray-400 xl:absolute"
					class:xl:pointer-events-none={!isSearching}
					on:click={async () => {
						isSearching = !isSearching;
						await tick();
						mobileSearchInput?.focus();
					}}
				>
					<Icon icon={isSearching ? cross : search} class="h-5 w-5" />
				</button>
				{#if isSearching}
					<div
						class="absolute left-0 right-0 top-full z-50 mt-px flex origin-top flex-col transition-transform duration-300 xl:bottom-auto xl:left-auto xl:w-[35.875rem] xl:min-w-full"
						style:max-height="calc(100dvh - {(header?.clientHeight ?? 0) + 1}px)"
					>
						<input
							bind:this={mobileSearchInput}
							type="search"
							class="text-primary placeholder:text-bito-black border-gray items-center border-b bg-white p-6 font-extrabold leading-none outline-none placeholder:font-normal xl:hidden"
							bind:value={searchText}
							placeholder="Vyhledat"
						/>
						<div class="-mt-px overflow-y-auto py-px">
							<AlgoliaResults {searchText} />
						</div>
					</div>
				{/if}
			</div>
		</div>
	</div>
</header>
