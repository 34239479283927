import { CaseStudyCategory } from "../CaseStudyCategory.js";
import { Locale, type LocalizedString } from "../Locale.js";

export const caseStudyCategoryUrlMap: Record<CaseStudyCategory, LocalizedString> = {
	[CaseStudyCategory.Agv]: {
		[Locale.cs]: "agv",
	},
	[CaseStudyCategory.DurableContainers]: {
		[Locale.cs]: "odolne-prepravky",
	},
	[CaseStudyCategory.ShelfSystem]: {
		[Locale.cs]: "regalove-systemy",
	},
	[CaseStudyCategory.SteelPlatforms]: {
		[Locale.cs]: "ocelove-plosiny",
	},
	[CaseStudyCategory.StorageTowers]: {
		[Locale.cs]: "skladovaci-veze",
	},
};
