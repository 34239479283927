<script lang="ts">
	import type { MenuItem } from "../../../../core/schema/MenuItem";
	import Icon from "../Icon.svelte";
	import close from "../../../../core/assets/icons/close.svg?raw";
	import bito from "../../../../core/assets/icons/bito.svg?raw";
	import Menu from "./Menu.svelte";

	export let isOpen = false;
	export let menu: MenuItem[];

	function onClose(): void {
		isOpen = false;
	}

	function onClick(): void {
		isOpen = false;
	}
</script>

<nav class="block {isOpen && 'fixed left-0 top-0 z-50 m-0 flex h-full w-[16.0625rem] flex-col bg-white py-3'}">
	<div class="flex items-start justify-between px-4">
		<a href="/" class="w-[4.5rem]">
			<h2 class="text-primary">
				<Icon icon={bito} class="h-8 w-12" />
			</h2>
		</a>
		<button class="mt-1" on:click={onClose}>
			<span
				class="text-2xs hover:text-primary flex items-center gap-1 font-semibold leading-5 transition-colors duration-300 ease-in-out"
			>
				Zavřít
				<Icon icon={close} class="h-2 w-2" />
			</span>
		</button>
	</div>

	<Menu {menu} on:click={onClick} />
</nav>
