<script lang="ts">
	import { getGlobalSettings } from "../getGlobalSettings.js";

	const { COOKIES } = getGlobalSettings();

	/* eslint-disable svelte/no-at-html-tags */
</script>

<svelte:head>
	<link rel="preconnect" href="https://cdn.cookie-script.com" />
</svelte:head>

{@html `<script type="text/javascript" charset="UTF-8" src="https://cdn.cookie-script.com/s/${COOKIES}.js" fetchpriority="low"></script>`}
