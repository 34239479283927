<script lang="ts">
	import phone from "../assets/images/phone.svg?raw";
	import Button from "./Button.svelte";
	import { createPromiseStore } from "../../../core/utils/createPromiseStore.js";
	import { mutation } from "../appsync/graphql.js";
	import sendPhoneForm from "../appsync/mutations/sendPhoneForm.graphql";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import Modal from "./Modal.svelte";
	import Icon from "./Icon.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";
	import Form from "./Form/Form.svelte";
	import cross from "../../../core/assets/icons/cross.svg?raw";
	import { onMount } from "svelte";

	let isOpened = false;

	const globalSettings = getGlobalSettings();

	const send = createPromiseStore(async (input: string) => {
		await mutation(globalSettings.PUBLIC_GRAPHQL_ENDPOINT, sendPhoneForm, { input });
	});

	let showSuccessModal = false;
	async function submit(event: SubmitEvent): Promise<void> {
		const form = event.target as HTMLFormElement;
		const formData = new FormData(form);
		await send.call(String(formData.get("phoneNumber")));
		isOpened = false;
		showSuccessModal = true;
	}

	function onWindowScroll(): void {
		if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
			isOpened = true;
		}
	}

	onMount(() => {
		window.addEventListener("scroll", onWindowScroll);

		return () => {
			window.removeEventListener("scroll", onWindowScroll);
		};
	});
</script>

{#if isOpened}
	<button
		class="fixed inset-0 z-20 cursor-default"
		on:click={() => {
			isOpened = false;
		}}
	></button>
{/if}

<Modal show={showSuccessModal}>
	<p class="h2" slot="title">Děkujeme za zprávu</p>
	<div class="flex flex-col items-center gap-2">
		<p>Vaše zpráva byla úspěšně odeslána. Brzy se vám ozveme.</p>

		<Button
			class="px-6"
			on:click={() => {
				showSuccessModal = false;
			}}
		>
			Rozumím
		</Button>
	</div>
</Modal>

<!-- <svelte:window bind:scrollY bind:innerHeight /> - When svelte window is present on this, scrolling into view using anchor won't work -->

<div
	class="from-tertiary to-primary fixed bottom-0 right-0 h-[4.5rem] bg-gradient-to-br transition-[width] delay-[10ms] duration-300 ease-out sm:h-[5rem] md:delay-0 {isOpened
		? 'z-[100000] w-full min-[450px]:w-[21.5rem]'
		: 'z-20 w-[4.5rem] sm:w-[5rem]'}"
>
	<button
		on:click={() => {
			isOpened = true;
		}}
		class="flex h-full w-full items-center justify-start"
	>
		<div
			class="flex aspect-square h-[4.5rem] w-[4.5rem] items-center justify-center text-white sm:h-[5rem] sm:w-[5rem]"
		>
			<Icon icon={phone} class="w-9 sm:w-11" />
		</div>
		<Form on:submit={submit} class="flex flex-col">
			<div class="flex gap-px">
				<div class="flex items-center justify-center gap-2 bg-white px-3">
					<span class="text-2xs">+420</span>
				</div>
				<input
					aria-label="Telefonní číslo"
					id="callUsPhoneNumber"
					name="phoneNumber"
					required
					type="tel"
					class="w-[7.438rem] rounded-none text-center font-bold outline-none"
				/>
			</div>
			<Button variant="primary" type="submit" class="mt-1 !py-[.375rem]">{nbspify("Zavolejte mi")}</Button>
		</Form>
		<p class="ml-2 max-w-[4.5rem] text-left text-xs font-bold leading-[1.15] text-white">
			{nbspify("Nechejte nám číslo, zavoláme!")}
		</p>
	</button>
	{#if isOpened}
		<button
			on:click={() => {
				isOpened = false;
			}}
			class="border-secondary absolute -top-3.5 right-4 z-[999999] grid aspect-square w-6 place-items-center border bg-white p-1"
		>
			<Icon icon={cross} class="text-secondary h-full w-full" />
		</button>
	{/if}
</div>
